<template>
  <div class="semifisheds">
    <semifisheds-top-panel />
    <semifisheds-table />
  </div>
</template>

<script>
import SemifishedsTable from './semifisheds-table/semifisheds-table.vue';
import SemifishedsTopPanel from './semifisheds-top-panel/semifisheds-top-panel.vue';

export default {
  name: 'Semifinisheds',
  components: { SemifishedsTable, SemifishedsTopPanel },
};
</script>

<style lang="scss" scoped></style>
