<template>
  <div class="semifisheds-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="tableRules"
      @reload="reloadData"
      @edit="editFn"
      @delete="deleteFn"
    />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters } from 'vuex';
import { appLocalStorage } from '@/app/shared/services';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import {
  FETCH_SEMIFINISHEDS,
  FETCH_SEMIFINISHED,
  DELETE_SEMIFINISHED,
} from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'SemifishedsTable',
  components: { AppTable },
  mixins: [notifyMixin],
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 80 },
        { label: 'Название', value: 'title' },
        { label: 'Время приготовления', value: 'cooking_time' },
      ],
      tableRules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      filter: appLocalStorage.getItem('filters').semifisheds,
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
  },
  async mounted() {
    if (this.filter) {
      await this.fetch({ filter: this.filter.value });
    } else {
      await this.fetch();
    }
  },
  methods: {
    ...mapActions({
      fetch: FETCH_SEMIFINISHEDS,
      select: FETCH_SEMIFINISHED,
      delete: DELETE_SEMIFINISHED,
    }),
    async reloadData(page) {
      if (this.filter) {
        await this.fetch({ filter: this.filter.value, page });
      } else {
        await this.fetch({ page });
      }
    },
    async editFn(semis) {
      await this.select(semis.id);
      this.$router.push(`semifinisheds/${semis.id}`);
    },
    deleteFn(semis) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(semis.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Полуфабрикат успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>
