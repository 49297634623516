<template>
  <el-dialog :title="title" :visible.sync="visible" width="40%" :before-close="closeModal">
    <el-form ref="semifinisheds" :model="semifinished" @submit.native.prevent="saveFn">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Название" prop="title">
            <el-input v-model="semifinished.title" placeholder="Фарш" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Штрихкод" prop="barcode">
            <el-input v-model="semifinished.barcode" placeholder="124141241" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Цех" prop="workshop">
            <el-input v-model="semifinished.workshop" placeholder="Производственный" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Налог" prop="tax">
            <el-input v-model="semifinished.tax" placeholder="200" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Время приготовления" prop="cooking_time">
            <el-time-picker
              v-model="semifinished.cooking_time"
              placeholder="Время приготовления"
              value-format="HH:mm"
              format="HH:mm"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Процесс приготовления" prop="cooking_process">
        <el-input
          v-model="semifinished.cooking_process"
          type="textarea"
          placeholder="Процесс приготовления"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Закрыть</el-button>
      <el-button type="success" :loading="loading" @click="saveFn"> Сохранить </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOADING, ERROR } from '../shared/state/types/getter_types';
import { STORE_SEMIFINISHED } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'GuideModal',
  mixins: [notifyMixin],
  props: {
    title: {
      type: String,
      default: 'Добавить блюдо',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      semifinished: {},
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
    }),
  },
  methods: {
    ...mapActions({
      store: STORE_SEMIFINISHED,
    }),
    closeModal() {
      this.visible = false;
    },
    async saveFn() {
      await this.store(this.semifinished);
      if (this.error) {
        this.showErrorMessage('Ошибка добавления');
      }
      this.showSuccessMessage('Успешно добавлен');
      this.closeModal();
    },
  },
};
</script>
