<template>
  <div>
    <div class="top-menu is-flex is-justify-content-flex-end">
      <el-button type="primary" @click="showModal = true"> Добавить полуфабрикат </el-button>
    </div>
    <semifisheds-modal :show.sync="showModal" />
  </div>
</template>

<script>
import SemifishedsModal from '../semifisheds-modal/semifisheds-modal.vue';

export default {
  name: 'SemifishedsTopPanel',
  components: { SemifishedsModal },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>
